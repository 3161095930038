/* ------------------------------------ *\
    LINKS
\* ------------------------------------ */

a,
.link {
    display: inline-block;
    color: $theme-color;
    text-decoration: none;

    @include input(hoverable) {
        &:hover {
            text-decoration: underline;
        }
    }

    &--dark {
        color: $base-text-color;
        @include input(hoverable) {
            &:hover {
                color: $theme-color;
                text-decoration: none;
            }
        }
    }

    &--light {
        color: $theme-color;

        @include input(hoverable) {
            &:hover {
                text-decoration: underline;
            }
        }
    }

    &--bold {
        font-weight: $font-weight--bold;
    }

    &--icon {
        position: relative;
        padding-left: $space * 2;

        & > .fa {
            position: absolute;
            top: 2px; //default for .fa-angle-*
            left: 0;
        }
    }

    &--nohover {
        color: $base-text-color;
        &:hover {
            text-decoration: none;
        }
    }
}

;@import "sass-embedded-legacy-load-done:103";