//TODO: it would be better to decrease the specificity here
.o-category-tree {
    &__header {
        &.o-category-tree__header--small {
            margin-bottom: $space * 7;
            margin-left: 0;
        }
    }

    &__wrapper.o-category-tree__stump & {
        &__block {
            padding-right: $space * 2;

            &-item {
                margin-right: 0;
                margin-bottom: $space * 6;
            }
        }
    }
}
;@import "sass-embedded-legacy-load-done:276";