.third-picture-block {
    &__content {
        margin-bottom: $space * 14;
    }

    &__image {
        .o-full-picture-block {
            margin-bottom: 0;

            &__image {
                aspect-ratio: 1/1;
            }
        }
    }

    &__text {
        margin-top: $space * 6;

        .m-rich-text__content {
            margin: 0;
        }
    }
}

;@import "sass-embedded-legacy-load-done:459";