@include media(xxl) {
    .cms-last-bought-wishlist {
        &__list-container {
            grid-template-columns: repeat(2, 1fr);
            column-gap: 14 * $space;
        }

        margin-bottom: $space * 28;
    }
}

;@import "sass-embedded-legacy-load-done:588";