@include media(lg) {
    .related-stories {
        &__headline {
            padding: ($space * 14) 0;
        }

        &__items {
            flex-wrap: nowrap;
            padding-bottom: $space * 12;
        }

        &__item {
            flex-basis: 350px;
        }
    }
}

;@import "sass-embedded-legacy-load-done:549";