/* ------------------------------------ *\
    BRANDPAGES
\* ------------------------------------ */

$brandpage-carousel-height: 275px;
$brandpage-carousel-image-height: 168px;
$new-brandpage-logo-height: 70px;
$new-brandpage-big-block-height: 548px;
$new-brandpage-small-block-height: 269px;
$new-brandpage-image-height: 200px;

.brandpage {
    &__banner {
        position: relative;
        overflow: hidden;
        background: top/cover no-repeat;
    }

    &__carousel {
        position: relative;
        overflow: hidden;
        height: $brandpage-carousel-height;

        &-btn--top {
            position: absolute;
            top: 225px;
            left: 0;
            height: 50px;
        }

        &-btn {
            overflow: hidden;
        }

        &-btn--top .fa,
        &-btn .fa {
            position: absolute;
            right: 10px;
            line-height: $line-height--medium;
            font-size: $font-size--smaller;
        }

        &-link {
            overflow: hidden;

            &:hover > img {
                transform: scale(1.1);
            }

            .category__img {
                overflow: hidden;
                transition: transform 0.2s ease-in;
                height: 140px;
            }

            .top-category__img {
                overflow: hidden;
                transition: transform 0.2s ease-in;
                height: 275px;
            }
        }

        &-item {
            justify-content: center;
            display: flex;
            height: 275px;
            position: relative;
            min-width: 100%;

            img {
                height: 275px;
            }

            @include media(md) {
                min-width: 50%;
            }

            @include media(lg) {
                min-width: percentage(calc(1 / 3));
            }
        }

        &-item.first img {
            @include media(xl) {
                margin-left: 0;
                left: 0;
            }
        }

        &-item.last img {
            @include media(xl) {
                margin-left: 0;
                left: auto;
                right: 0;
            }
        }
    }

    &__logo {
        height: 60px;

        img {
            max-height: 50px;
        }
    }

    &__products {
        .product-widget {
            height: 430px;

            @include media(lg) {
                height: 410px;
            }

            &__image {
                height: 180px;
            }

            &__data {
                height: 220px;
            }
        }
    }

    &__product-widget .product-widget {
        &__image {
            height: 200px;

            @include media(lg) {
                height: 180px;
            }
        }

        &__badge-container {
            height: 45px;
            position: absolute;
            bottom: 0;

            @include media(lg) {
                height: 20px;
            }
        }

        &__sku-container {
            height: 14px;
        }

        &__delivery-container {
            height: 19px;
        }

        &__info {
            height: 94px;
        }

        &__prices {
            height: 76px;
        }

        &__tax {
            height: 28px;
        }

        &__footer {
            height: 40px;
        }

        .m-product-price__price--hint {
            height: 18px;
        }

        .a-txt__price--large {
            height: 35px;
        }

        .o-product-widget__price-per-unit {
            height: 28px;
        }
    }
}

.brandpage-ind {
    &__box {
        padding-right: 45px;

        & > .btn {
            position: absolute;
            right: $space * 2;
            top: 50%;
            transform: translate3d(0, -50%, 0);
        }
    }

    &__img-box {
        height: 100px;
        overflow: hidden;

        @include media(lg) {
            height: 175px;
        }

        @include media(xl) {
            height: 220px;
        }

        @include media(xxl) {
            height: 300px;
        }

        & > h1 {
            @include media(lg) {
                position: absolute;
                bottom: 0;
                left: 0;
                background-color: rgba(#fff, 0.85);
            }
        }
    }

    &__category-buttons {
        &--mobile {
            display: block;

            @include media(lg) {
                display: none;
            }
        }

        display: none;

        @include media(lg) {
            height: 58px;
            display: flex;
        }

        &-8 {
            @include media(lg) {
                height: 116px;
            }

            &--mobile {
                height: 532px;
            }
        }

        &-6 {
            @include media(lg) {
                height: 116px;
            }

            @include media(xl) {
                height: 58px;
            }

            &--mobile {
                height: 396px;
            }
        }

        &-4 {
            &--mobile {
                height: 266px;
            }
        }

        &-3 {
            &--mobile {
                height: 198px;
            }
        }
    }
}

.new-brandpage {
    &__title {
        padding: $space * 3;
        margin-bottom: $space * 4;
        margin-top: $space * 4;
    }

    &__top-products-headline {
        line-height: $line-height--xlarge;
        text-align: center;
    }

    &__products-headline {
        &--basic {
            font-size: $font-size--xlarge;
            line-height: $line-height--xlarge;

            @include media(md) {
                font-size: $font-size--xlarge-plus;
                line-height: 43px;
            }
        }

        &-container {
            margin: 0 ($space * 2) ($space * 8);

            &--center {
                text-align: center;
            }
            &--left {
                text-align: left;
            }

            @include media(md) {
                margin-bottom: $space * 14;
            }
        }
    }

    &__new-products-headline {
        line-height: $line-height--xlarge;
        text-align: center;

        @include media(lg) {
            text-align: left;
        }
    }

    &__brand-media-headline {
        line-height: $line-height--xlarge;
        text-align: center;
    }

    &__logo-container {
        position: absolute;
        left: 0;
        z-index: 1;
        text-align: center;
        width: 100%;
    }

    &__logo-image {
        max-height: $new-brandpage-logo-height;
        border-radius: 0 0 6px 6px;

        @include border($color: white, $width: ($space * 3));
    }

    &__banner-container {
        height: 250px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    &__menu {
        .container {
            padding-top: 1px;
        }
    }

    &__menu-button {
        text-align: left;
        &:hover {
            filter: brightness(90%);
        }

        @include media(md) {
            text-align: center;
        }
    }

    &__menu-link {
        padding: 10px 20px;
        width: 100%;

        &:hover {
            text-decoration: none;
        }

        @include media(md) {
            padding: ($space * 3) ($space * 2);
        }
    }

    &__carousel-item {
        display: flex;
        position: relative;
        padding: $space * 2;

        @include media(lg) {
            min-width: percentage(calc(1 / 3));
        }

        @include media(md) {
            min-width: 100%;
            justify-content: center;
        }

        @include border($color: white, $width: ($space), $where: 'bottom');

        &--assortiment {
            flex-wrap: wrap;
            min-width: 167px !important;
        }

        a:hover {
            text-decoration: none;
        }
    }

    &__slider {
        @include media(lg) {
            padding: 1px 50px 0;
        }

        > div {
            border: 0;
        }
    }

    &__link-chevron {
        padding: $space;
        float: right;
    }
}

.top-category {
    &--1 {
        width: percentage(calc(1 / 1));
    }

    &--2 {
        width: percentage(calc(1 / 2));
    }

    &--3 {
        width: percentage(calc(1 / 3));
    }

    &--4 {
        width: percentage(calc(1 / 4));
    }

    &--5 {
        width: percentage(calc(1 / 5));
    }

    &--6 {
        width: percentage(calc(1 / 6));
    }
}

.top-product {
    &--block {
        padding-top: $space * 10;
        padding-bottom: $space * 6;
    }

    &--wrapper {
        @include media(xl) {
            display: flex;
            justify-content: space-between;
        }
    }

    &--1,
    &--2 {
        background-color: white;
        overflow: hidden;
    }

    &--1 {
        @include media(xl) {
            height: $new-brandpage-big-block-height;
        }
    }

    &__image1 {
        height: 270px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    &--2 {
        @include media(md) {
            display: flex;
            height: $new-brandpage-small-block-height;
        }

        img {
            max-height: $new-brandpage-image-height;
            margin: 0;

            @include media(md) {
                margin: ($space * 6) ($space * 2);
            }

            @include media(lg) {
                margin: ($space * 6) ($space * 4);
            }

            @include media(xxl) {
                margin: $space * 6;
            }
        }
    }
}

.brand-categories {
    padding: 0;
    width: 92%;
    top: 11px;
    position: absolute;

    &__headline {
        width: percentage(calc(8 / 10));

        @include media(xl) {
            width: 100%;
        }
    }

    & > div:first-child {
        padding-bottom: $space * 4;

        @include media(xl) {
            padding-bottom: 0;
        }
    }

    &__headline-border {
        position: absolute;
        width: 110%;
        margin-left: -15px;

        @include border($where: 'bottom', $width: 2px, $color: $grey--light);

        @include media(xl) {
            display: none;
            visibility: hidden;
        }
    }

    @include media(xl) {
        width: 100%;
        padding: $space * 4;
        margin-top: $space * 10;
        position: relative;
        box-shadow: $shadow6;

        .brand-categories-page & {
            position: relative;
        }

        @include border();
    }
}

.brand-categories-page {
    .brand-categories {
        width: 87%;
    }
}

.assortment {
    margin-top: 50px;
    padding-left: 20px;

    &__items {
        display: none;
        flex-wrap: wrap;
        a:hover {
            text-decoration: none;
        }

        @include media(lg) {
            display: flex;
        }
    }

    &__text {
        position: relative;

        div {
            max-width: 100%;
            text-align: center;
        }
    }

    &__image {
        max-width: 150px;
    }

    &__headline {
        line-height: 36px;
        text-align: center;
        padding-bottom: $space * 4;
        margin-bottom: $space * 4;

        @include media(xl) {
            text-align: left;
            padding-bottom: 0;
            margin-bottom: 0;
        }
    }

    &__button {
        min-width: 280px;
        margin-top: $space * 6;
        margin-bottom: $space * 6;
    }
}

.brand-media {
    margin-top: $space * 6;
    padding-bottom: $space * 6;
    padding-top: $space * 6;
    order: 2;
    width: 100%;

    @include media(lg) {
        order: 1;
    }

    &__text {
        order: 2;
        background-color: white;
        margin-top: -1px;
        padding: 10px 15px 20px;
        font-size: 14px;

        @include media(md) {
            padding: 20px 30px;
        }

        @include media(xl) {
            order: 1;
            margin-top: 0;
            margin-right: -1px;
            padding: 50px 10px 50px 50px;
        }

        h1 {
            margin-bottom: $space * 5;
        }
    }

    &__content {
        margin-top: $space * 6;
    }

    &__video {
        order: 1;
        background-color: white;
        padding: 15px;

        @include media(xl) {
            order: 2;
            padding: 50px 50px 50px 0;
        }
    }
}

.category-products__search {
    margin-top: 0;
    order: 1;

    @include media(lg) {
        margin-top: $space * 10;
        visibility: visible;
        display: flex;
        flex-wrap: wrap;
        order: 2;
    }
}

.seo-content {
    margin-top: $space * 6;
}

.additional-breadcrumbs {
    position: absolute;
    bottom: 0;
    left: 70px;
}

.bestsellers {
    padding-top: $space * 4;
    padding-bottom: $space * 10;

    &--carousel {
        min-width: 100%;

        @include media(lg) {
            min-width: 50%;
        }
    }
}

.show-sortiment {
    padding: $space * 3;
    margin: auto auto 20px;
    width: 100%;
    max-width: 290px;
    display: flex;
}

;@import "sass-embedded-legacy-load-done:233";