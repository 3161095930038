@include media(xxl) {
    .template-campaign {
        .o-cms-video {
            &__container {
                margin-bottom: $space * 20;

                &--any-where-else,
                &--after-cta-button {
                    margin-top: $space * 20;
                }
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:534";