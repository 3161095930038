@include media(lg) {
    .accordion {
        display: block;
        margin-bottom: $space * 4;

        .tab {
            @include border($where: 'bottom', $width: 3px, $color: $blue);

            &.collapsed {
                border-color: transparent;
            }

            &__content {
                padding: ($space * 2) 0;

                &.collapsed {
                    display: none;
                }
            }

            &__header {
                font-weight: bold;
            }
        }

        .list__header {
            margin: ($space * 2) 0;
            padding-bottom: $space * 2;
            cursor: pointer;
            @include border($where: 'bottom', $width: 1px, $color: $grey);
        }
    }
}

;@import "sass-embedded-legacy-load-done:197";