@include media(md) {
    .anchor-links-block {
        &__header-container {
            margin: ($space * 4) 0;
        }

        &__description-container {
            margin: ($space * 4) 0;
        }

        &__anchors-container {
            margin: ($space * 8) 0 ($space * 4) 0;
        }
    }
}

;@import "sass-embedded-legacy-load-done:198";