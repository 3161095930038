@include media(xxl) {
    .template-blogpost,
    .table-of-contents__container {
        padding-left: $space * 25;
        padding-right: $space * 25;
    }

    .related-stories__container {
        padding-left: $space * 3;
        padding-right: $space * 3;
    }
}

;@import "sass-embedded-legacy-load-done:271";