.anchor-links-block {
    background-color: $grey--darker;
    margin-bottom: $space * 14;
    @include grow-outside-container;

    &__content-container {
        padding-top: $space * 4;
        padding-bottom: $space * 4;
    }

    &__header-container {
        margin: 0;
    }

    &__header {
        @include text-variant(extra);
    }

    &__description-container {
        margin: ($space * 4) 0;
    }

    &__anchors-container {
        margin: ($space * 8) 0 ($space * 4);
    }
}

;@import "sass-embedded-legacy-load-done:196";