.cms-dropdown {
    height: 46px;
    min-width: 146px;
    display: inline-block;
    position: relative;

    &__title {
        margin-right: $space * 5;
    }

    &__content {
        @include border();
        position: absolute;
        width: 335px;
        margin-top: -1px;
        background-color: #fff;
        border-radius: 0 0 $border-radius $border-radius;
        padding: $space * 2;
        z-index: $z2;
        box-shadow: $shadow2;

        .form__field--radio {
            border-bottom: none;

            &.selected {
                color: $blue;
                font-weight: $font-weight--bold;
            }
        }

        label {
            font-size: $font-size--medium;
            line-height: $line-height--small;
        }
    }

    &.opened & {
        &__btn {
            z-index: $z5;
            border-bottom-color: #fff;
            box-shadow: 0 -1px 2px 0 rgba(#000, 0.24);
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    &__btn {
        width: 100%;
        height: 100%;
        padding: ($space * 3) ($space * 4);
        position: relative;
    }

    &__arrow-icon {
        font-size: $font-size--regular !important;
        right: $space * 2;
    }
}

;@import "sass-embedded-legacy-load-done:388";