@include media(xxl) {
    .related-stories {
        &__item {
            flex-basis: 405px;

            &--last {
                display: flex;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:551";