@include media(lg) {
    .o-cms-product-list {
        &__product-list-grid {
            grid-template-columns: repeat(3, 33.33%);
        }

        &__mobile-filter-button {
            display: none;
        }

        &__filter {
            display: block;
        }
    }
}

;@import "sass-embedded-legacy-load-done:408";