@include media(md) {
    .o-cms-video {
        &__headline-container {
            margin-bottom: $space * 8;
        }

        &__container {
            margin-bottom: $space * 14;
            &--on-top {
                margin-top: $space * 8;
            }
            &--any-where-else,
            &--after-cta-button {
                margin-top: $space * 14;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:516";