@include media(lg) {
    .anchor-links-block {
        margin-bottom: $space * 20;
        &__header-container {
            margin: ($space * 10) 0;

            .anchor-links-block__header {
                @include text-variant(giant);
            }
        }

        &__description-container {
            margin: ($space * 8) 0;
        }

        &__anchors-container {
            margin: ($space * 8) 0 ($space * 10);
        }
    }
}

;@import "sass-embedded-legacy-load-done:222";