@include media(md) {
    .related-stories {
        &__headline {
            @include text-variant(extra-plus);
        }

        &__item {
            &-title {
                font-size: $font-size--large;
                line-height: $line-height--large-plus;
                margin: ($space * 4) 0;
            }
        }

        &__footer {
            font-size: $font-size--regular-plus;
            line-height: $line-height--regular;
        }
    }
}

;@import "sass-embedded-legacy-load-done:538";