/* ------------------------------------ *\
    FLEXBOX
\* ------------------------------------ */

.flex { display: flex; }
.flex--none { flex: none; }
.item--fit { flex: 1 1 auto; } //class used because of IE10 only, makes text wrap and elements take full width as flex children
.item--grow { flex-grow: 1; }

.flex--column { flex-direction: column; }
.flex--row-reverse { flex-direction: row-reverse; }

.items--start { align-items: flex-start; }
.items--end { align-items: flex-end; }
.items--center { align-items: center; }
.items--baseline { align-items: baseline; }

.flex--start { justify-content: flex-start; }
.flex--end { justify-content: flex-end; }
.flex--center { justify-content: center; }
.flex--between { justify-content: space-between; }
.flex--around { justify-content: space-around; }
.flex--wrap { flex-wrap: wrap; }
.flex--noshrink { flex-shrink: 0; }

@include generate-breakpoint-prefixes($class-name: 'flex--nowrap') {
    & {
        flex-wrap: nowrap;
    }
}

@include generate-breakpoint-prefixes($class-name: 'order') {
    @for $i from 1 through 4 {
        &-#{$i} { order: #{$i}; }
    }
}

;@import "sass-embedded-legacy-load-done:72";