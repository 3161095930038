.m-rich-text {
    &__header {
        text-align: center;
    }

    &__header-container {
        margin-bottom: $space * 8;
        padding: 0 ($space * 6);
    }

    &__content {
        margin: ($space * 14) 0;

        &--without-header {
            margin-top: $space * 8;
        }

        p {
            font-size: $font-size--medium;
            line-height: $line-height--large;
            margin-bottom: $space * 8;

            em {
                font-style: italic;
            }
        }

        h2 {
            font-family: $font-family--main;
            font-size: $font-size--xlarge;
            font-weight: $font-weight--bold;
            line-height: $line-height--xlarge;
            margin-bottom: $space * 8;
        }

        h3 {
            font-family: $font-family--main;
            font-size: $font-size--xlarge;
            font-weight: $font-weight--bold;
            line-height: $line-height--large-plus;
            margin-bottom: $space * 8;
        }

        h4 {
            font-family: $font-family--main;
            font-size: $font-size--large;
            font-weight: $font-weight--bold;
            line-height: $line-height--large;
            margin-bottom: $space * 6;
        }

        h5 {
            font-size: $font-size--medium-plus;
            font-weight: $font-weight--bold;
            line-height: $line-height--large;
            font-family: $font-family--regular;
            margin-bottom: $space * 4;
        }

        h6 {
            font-size: $font-size--large;
            font-weight: $font-weight--base;
            line-height: $line-height--large;
            font-family: $font-family--regular;
            margin-bottom: $space * 4;
            text-transform: uppercase;
        }

        ul {
            margin-bottom: $space * 8;
        }

        ul > li {
            position: relative;
            padding-left: $space * 4;
            font-size: $font-size--medium;
            line-height: $line-height--large;

            em {
                font-style: italic;
            }

            &::before {
                position: absolute;
                content: '■';
                font-family: $font-family--font-awesome;
                left: 0;
                color: $theme-color;
            }
        }

        a {
            color: $theme-color;
        }

        .text-center {
            text-align: center;
        }

        .text-left {
            text-align: left;
        }
    }
}

.template-campaign {
    .m-rich-text__content--without-header {
        margin-top: $space * 6;
    }
}

;@import "sass-embedded-legacy-load-done:357";