@include media(md) {
    .picture-block {
        &__content {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: flex-start;
        }

        &__image {
            padding: 0;
        }

        &__text-container {
            padding: $space * 6 $space * 8;
        }
    }
}

;@import "sass-embedded-legacy-load-done:727";