//TODO: it would be better to decrease the specificity here
@include media(lg) {
    .o-category-tree {
        &__header {
            &.o-category-tree__header--small {
                margin-bottom: $space * 8;
            }
        }

        &__wrapper.o-category-tree__stump & {
            &__block {
                padding-left: 0;
                padding-right: $space * 6;

                &-item {
                    margin-bottom: $space * 4;
                }
            }
        }
    }
}
;@import "sass-embedded-legacy-load-done:293";