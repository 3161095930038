.o-cms-video {
    &__headline-container {
        text-align: center;
        margin-bottom: $space * 4;
    }

    &__container {
        @include grow-outside-container;
        aspect-ratio: 16 / 9;
        margin-bottom: $space * 8;
        &--on-top {
            margin-top: $space * 6;
        }
        &--any-where-else,
        &--after-cta-button {
            margin-top: $space * 8;
        }
    }

    &__iframe {
        width: 100%;
        height: 100%;
    }
}

;@import "sass-embedded-legacy-load-done:504";