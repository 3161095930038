.cms-pic,
.cms-hero {
    position: relative;
    background: #fff no-repeat top center;
    background-size: cover;
}

.cms-pic {
    height: 160px;

    &--press {
        background-position: center;
    }
}

.cms-pic--md,
.cms-pic--xl {
    height: 173px;
}

.cms-hero {
    background-image: url(https://media.contorion.de/content/cms/jobs/team-heroshot.jpg);
    height: 245px;
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    justify-content: center;
}

.cms-icon {
    @include border($width: 3px);
    position: relative;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    color: $grey;

    & > svg,
    & > .fa {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
    }

    & > .fa {
        text-align: center;
        font-size: $font-size--xlarge;
    }

    & > [class^="icon--"] {
        font-size: 50px;
        line-height: 76px;
    }
}

.cms-list > li {
    position: relative;
    padding-left: $space * 2;

    &::before {
        position: absolute;
        content: $fa-var-angle-right;
        font-family: $font-family--font-awesome;
        left: 0;
        color: $theme-color;
    }
}

@include media(md) {
    .cms-pic--md,
    .cms-pic--xl {
        height: 262px;
    }

    .cms-pic--press {
        height: inherit;
    }
}

@include media(lg) {
    .cms-pic {
        height: 145px;

        &--md {
            height: 200px;
        }

        &--xl {
            height: 277px;
        }
    }

    .cms-hero {
        height: 275px;
    }
}

@include media(xl) {
    .cms-pic {
        height: 210px;

        &--md {
            height: 250px;
        }

        &--xl {
            height: 348px;
        }
    }

    .cms-hero {
        height: 390px;
    }
}

@include media(xxl) {
    .cms-pic {
        height: 210px;

        &--md {
            height: 335px;
        }

        &--xl {
            height: 486px;
        }
    }
}

.cta-div {
    width: 100%;
    text-align: center;
    margin: ($space * 4) 0 ($space * 14);

    .button {
        height: 66px;
        width: calc(100% - 20px);
        padding: $space * 4;
    }

    @include media(md) {
        margin: ($space * 8) 0 ($space * 20);

        .button {
            width: auto;
            min-width: 351px;
        }
    }

    @include media(lg) {
        margin: ($space * 14) 0 ($space * 20);
    }

    @include media(xxl) {
        margin: ($space * 14) 0 ($space * 28);
    }
}

;@import "sass-embedded-legacy-load-done:172";