@include media(md) {
    .m-rich-text {
        &__header-container {
            margin-bottom: $space * 8;
            padding: 0 ($space * 4);
        }

        &__content {
            &--without-header {
                margin-top: $space * 14;
            }

            h2 {
                font-size: $font-size--xlarge-plus;
            }
        }
    }

    .template-campaign {
        .m-rich-text__content--without-header {
            margin-top: $space * 8;
        }
    }
}

;@import "sass-embedded-legacy-load-done:350";