/* ------------------------------------ *\
    TEXT
\* ------------------------------------ */

/* Text classes with MQ */
@include generate-breakpoint-prefixes($class-name: 'txt') {
    &--main { font-family: $font-family--main !important; }
    &--regular { font-family: $font-family--regular !important; }
    &--left { text-align: left !important; }
    &--center { text-align: center !important; }
    &--right { text-align: right !important; }
}

/* Simple text classes, no MQ */
.txt--bold { font-weight: $font-weight--bold !important; }
.txt--justify { text-align: justify !important; }
.txt--light { font-weight: $font-weight--light !important; }
.txt--italic { font-style: italic !important; }
.txt--caps { text-transform: uppercase !important; }
.txt--lined { text-decoration: line-through !important; }
.txt--underlined { text-decoration: underline !important; }
.txt--base { font-weight: $font-weight--base !important; }

/* Text colors */
.txt--theme-color { color: $theme-color !important; }
.txt--white { color: #fff !important; }
.txt--black { color: $grey--darker !important; }
.txt--grey { color: $grey--dark !important; }
.txt--grey--light { color: $grey !important; }
.txt--grey--medium { color: $grey--medium !important; }
.txt--blue { color: $blue !important; }
.txt--red { color: $red !important; }
.txt--green { color: $green !important; }
.txt--yellow { color: $yellow !important; }
.txt--orange { color: $orange !important; }

/* Text overflow */
.txt--nowrap {
    white-space: nowrap;
    overflow: hidden;
}

.txt--nowrap-visible { white-space: nowrap; }

.txt--truncate {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

small {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 11px;
    line-height: 14px;
}

b,
strong {
    font-weight: $font-weight--bold;
}

/* Extra large text (mostly for CMS pages) */
._p--res {
    line-height: 18px;

    @include media(lg) {
        font-size: $font-size--medium;
        line-height: 22px;
    }
}


/* Raw HTML */
.html--raw {
    p { margin: $space 0; }

    ul {
        padding: $space 0 $space ($space * 5);

        & > li::before {
            content: $fa-var-angle-right;
            font-family: $font-family--font-awesome;
            color: $theme-color;
        }
    }

    //Most of the time the raw HTML contains list elements outside <ul> or <ol>
    li {
        position: relative;
        list-style: none;
        padding-left: 12px;
        line-height: $line-height--list;

        &::before {
            position: absolute;
            content: "\2022";
            top: 0;
            left: 0;
        }
    }

    img { margin-top: $space * 3; }
}

;@import "sass-embedded-legacy-load-done:134";