@include media(sm) {
    .third-picture-block {
        &__image {
            .o-full-picture-block {
                justify-content: center;
                align-items: center;
                width: 100%;
                margin: 0;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:491";