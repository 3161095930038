.m-cms-h1 {
    &__container {
        margin-bottom: $space * 8;

        &--center {
            text-align: center;
        }

        &--left {
            text-align: left;
        }
    }
}

;@import "sass-embedded-legacy-load-done:320";