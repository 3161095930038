@include media(xl) {
    .o-cms-video {
        &__container {
            width: 100%;
            margin: 0 0 $space * 20;
            &--any-where-else,
            &--after-cta-button {
                margin-top: $space * 20;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:519";