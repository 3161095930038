/* ------------------------------------ *\
    PADDINGS
\* ------------------------------------ */

@include generate-breakpoint-prefixes($class-name: '_p', $short-name: true) {
    &4 { padding: $space * 4 !important; }
    &t4 { padding-top: $space * 4 !important; }
    &r4 { padding-right: $space * 4 !important; }
    &b4 { padding-bottom: $space * 4 !important; }
    &l4 { padding-left: $space * 4 !important; }
    &y4 {
        padding-top: $space * 4 !important;
        padding-bottom: $space * 4 !important;
    }
    &x4 {
        padding-left: $space * 4 !important;
        padding-right: $space * 4 !important;
    }

    &3 { padding: $space * 3 !important; }
    &t3 { padding-top: $space * 3 !important; }
    &r3 { padding-right: $space * 3 !important; }
    &b3 { padding-bottom: $space * 3 !important; }
    &l3 { padding-left: $space * 3 !important; }
    &y3 {
        padding-top: $space * 3 !important;
        padding-bottom: $space * 3 !important;
    }
    &x3 {
        padding-left: $space * 3 !important;
        padding-right: $space * 3 !important;
    }

    &2 { padding: $space * 2 !important; }
    &t2 { padding-top: $space * 2 !important; }
    &r2 { padding-right: $space * 2 !important; }
    &b2 { padding-bottom: $space * 2 !important; }
    &l2 { padding-left: $space * 2 !important; }
    &y2 {
        padding-top: $space * 2 !important;
        padding-bottom: $space * 2 !important;
    }
    &x2 {
        padding-left: $space * 2 !important;
        padding-right: $space * 2 !important;
    }

    &1 { padding: $space !important; }
    &t1 { padding-top: $space !important; }
    &r1 { padding-right: $space !important; }
    &b1 { padding-bottom: $space !important; }
    &l1 { padding-left: $space !important; }
    &y1 {
        padding-top: $space !important;
        padding-bottom: $space !important;
    }
    &x1 {
        padding-left: $space !important;
        padding-right: $space !important;
    }

    &0 { padding: 0 !important; }
    &t0 { padding-top: 0 !important; }
    &r0 { padding-right: 0 !important; }
    &b0 { padding-bottom: 0 !important; }
    &l0 { padding-left: 0 !important; }
    &x0 {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    &y0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
}

;@import "sass-embedded-legacy-load-done:138";